// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root{height:100%}.ant-btn>.svg-inline--fa+span{margin-inline-start:8px}", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA,MACE,WAAA,CAGF,8BACE,uBAAA","sourcesContent":["@import 'antd/dist/reset.css';\n\n#root {\n  height: 100%;\n}\n\n.ant-btn > .svg-inline--fa + span {\n  margin-inline-start: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
