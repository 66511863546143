"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSearchRatesTargetIata = exports.setSearchRatesOriginIata = exports.clearSearchRatesInputs = exports.clearSearchRatesResults = exports.ratesSearchSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const RatesSearchTypes_1 = require("./RatesSearchTypes");
const RatesSearchAsyncActions_1 = require("./RatesSearchAsyncActions");
exports.initialState = {
    inputs: {},
    loading: false,
    results: undefined,
};
exports.ratesSearchSlice = (0, toolkit_1.createSlice)({
    name: RatesSearchTypes_1.ratesSearchNamespace,
    initialState: exports.initialState,
    reducers: {
        clearSearchRatesResults: (state, action) => {
            state.results = undefined;
        },
        clearSearchRatesInputs: (state, action) => {
            state.inputs = exports.initialState.inputs;
        },
        setSearchRatesOriginIata: (state, action) => {
            state.inputs.origin_iata = action.payload;
        },
        setSearchRatesTargetIata: (state, action) => {
            state.inputs.target_iata = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(RatesSearchAsyncActions_1.searchRatesAction.pending, (state, action) => {
            state.inputs = action.meta.arg.inputs;
            state.loading = true;
        });
        builder.addCase(RatesSearchAsyncActions_1.searchRatesAction.fulfilled, (state, action) => {
            state.results = action.payload;
            state.loading = false;
        });
        builder.addCase(RatesSearchAsyncActions_1.searchRatesAction.rejected, (state, action) => {
            console.log(action.error);
            state.loading = false;
            state.results = undefined;
        });
    }
});
_a = exports.ratesSearchSlice.actions, exports.clearSearchRatesResults = _a.clearSearchRatesResults, exports.clearSearchRatesInputs = _a.clearSearchRatesInputs, exports.setSearchRatesOriginIata = _a.setSearchRatesOriginIata, exports.setSearchRatesTargetIata = _a.setSearchRatesTargetIata;
