// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@fontsource/oxygen/400.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@fontsource/oxygen/700.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header{padding-top:2em;padding-bottom:2em;background:none !important;text-align:center}.Header h1{font:1.5em \"Oxygen\",arial,verdana,sans-serif;font-weight:bold}.Header h3{font:1em \"Oxygen\",arial,verdana,sans-serif;font-weight:normal;font-style:italic}", "",{"version":3,"sources":["webpack://./src/components/Header.scss"],"names":[],"mappings":"AAGA,QACE,eAAA,CACA,kBAAA,CACA,0BAAA,CACA,iBAAA,CAGF,WACE,4CAAA,CACA,gBAAA,CAGF,WACE,0CAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":["@import \"@fontsource/oxygen/400.css\";\n@import \"@fontsource/oxygen/700.css\";\n\n.Header {\n  padding-top: 2em;\n  padding-bottom: 2em;\n  background: none !important;\n  text-align: center;\n}\n\n.Header h1 {\n  font: 1.5em \"Oxygen\", arial, verdana, sans-serif;\n  font-weight: bold;\n}\n\n.Header h3 {\n  font: 1em \"Oxygen\", arial, verdana, sans-serif;\n  font-weight: normal;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
