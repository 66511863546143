"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_CONFIG = exports.OAUTH_CONFIG = void 0;
const getEnv = () => {
    return {
        OAUTH_PROVIDER: process.env.OAUTH_PROVIDER,
        OAUTH_REDIRECT_URI: process.env.OAUTH_REDIRECT_URI,
        OAUTH_CLIENT_ID: process.env.OAUTH_CLIENT_ID,
        AIRPORT_SEARCH_ENDPOINT: process.env.AIRPORT_SEARCH_ENDPOINT,
        AIRLINE_ROUTE_SEARCH_ENDPOINT: process.env.AIRLINE_ROUTE_SEARCH_ENDPOINT,
    };
};
function getEnvVarOrFail(env, key) {
    let value = env[key];
    if (value === undefined) {
        throw new Error(`Missing env var '${key}'`);
    }
    return value;
}
;
const getOAuthConfig = (env) => {
    return {
        provider: getEnvVarOrFail(env, "OAUTH_PROVIDER"),
        redirect_uri: getEnvVarOrFail(env, "OAUTH_REDIRECT_URI"),
        client_id: getEnvVarOrFail(env, "OAUTH_CLIENT_ID"),
        requested_scopes: ["openid", "email", "profile"],
    };
};
const getAppConfig = (env) => {
    return {
        airport_search_endpoint: getEnvVarOrFail(env, "AIRPORT_SEARCH_ENDPOINT"),
        airline_route_search_endpoint: getEnvVarOrFail(env, "AIRLINE_ROUTE_SEARCH_ENDPOINT"),
    };
};
const env = getEnv();
exports.OAUTH_CONFIG = getOAuthConfig(env);
exports.APP_CONFIG = getAppConfig(env);
