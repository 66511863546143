"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.airportSearchSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const AirportSearchTypes_1 = require("./AirportSearchTypes");
const AirportSearchAsyncActions_1 = require("./AirportSearchAsyncActions");
exports.initialState = {
    origin: {},
    target: {},
};
exports.airportSearchSlice = (0, toolkit_1.createSlice)({
    name: AirportSearchTypes_1.airportSearchNamespace,
    initialState: exports.initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(AirportSearchAsyncActions_1.searchOriginAirportAction.pending, (state, action) => {
            state.origin.loading = true;
            state.origin.options = [];
        });
        builder.addCase(AirportSearchAsyncActions_1.searchOriginAirportAction.fulfilled, (state, action) => {
            state.origin.loading = false;
            state.origin.options = action.payload;
        });
        builder.addCase(AirportSearchAsyncActions_1.searchOriginAirportAction.rejected, (state, action) => {
            console.log(action.error);
            state.origin.loading = false;
            state.origin.options = [];
        });
        builder.addCase(AirportSearchAsyncActions_1.searchTargetAirportAction.pending, (state, action) => {
            state.target.loading = true;
            state.target.options = [];
        });
        builder.addCase(AirportSearchAsyncActions_1.searchTargetAirportAction.fulfilled, (state, action) => {
            state.target.loading = false;
            state.target.options = action.payload;
        });
        builder.addCase(AirportSearchAsyncActions_1.searchTargetAirportAction.rejected, (state, action) => {
            console.log(action.error);
            state.target.loading = false;
            state.target.options = [];
        });
    }
});
