"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
require("./Footer.scss");
const { Text } = antd_1.Typography;
const Footer = () => {
    return ((0, jsx_runtime_1.jsx)("footer", Object.assign({ className: "Footer" }, { children: (0, jsx_runtime_1.jsx)("div", Object.assign({ className: "Footer-inside" }, { children: (0, jsx_runtime_1.jsx)(Text, Object.assign({ type: "secondary" }, { children: "prototype demo" })) })) })));
};
exports.Footer = Footer;
exports.default = exports.Footer;
