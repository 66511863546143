"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
const antd_2 = require("antd");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const UserProfile_1 = require("./UserProfile");
const UserProfileButton = (props) => {
    return ((0, jsx_runtime_1.jsx)(antd_2.Popover, Object.assign({ content: (0, jsx_runtime_1.jsx)(UserProfile_1.UserProfile, Object.assign({}, props)), trigger: "click", placement: "topRight" }, { children: (0, jsx_runtime_1.jsx)(antd_1.FloatButton, { icon: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faUser }), type: "default", shape: "square", style: { right: 24, bottom: 24 } }) })));
};
exports.UserProfileButton = UserProfileButton;
exports.default = exports.UserProfileButton;
