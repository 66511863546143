// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@fontsource/roboto/400.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "Footer{width:100%;text-align:center;height:60px;margin-top:-60px}.Footer-inside{max-width:750px;margin:0 auto;height:100%;padding-top:20px;font:1em \"Oxygen\",arial,verdana,sans-serif;font-weight:normal;font-style:italic}", "",{"version":3,"sources":["webpack://./src/components/Footer.scss"],"names":[],"mappings":"AAGA,OACE,UAAA,CACA,iBAAA,CACA,WAAA,CACA,gBAAA,CAGF,eACE,eAAA,CACA,aAAA,CACA,WAAA,CACA,gBAAA,CAEA,0CAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":["@import \"@fontsource/roboto/400.css\";\n\n\nFooter {\n  width: 100%;\n  text-align: center;\n  height: 60px;\n  margin-top: -60px;\n}\n\n.Footer-inside {\n  max-width: 750px;\n  margin: 0 auto;\n  height: 100%;\n  padding-top: 20px;\n\n  font: 1em \"Oxygen\", arial, verdana, sans-serif;\n  font-weight: normal;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
