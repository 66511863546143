"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const UserProfileSlice_1 = require("../features/users/UserProfileSlice");
const RatesSearchSlice_1 = require("../features/rates/RatesSearchSlice");
const AirportSearchSlice_1 = require("../features/airports/AirportSearchSlice");
const rootReducer = (0, redux_1.combineReducers)({
    userProfile: UserProfileSlice_1.userProfileSlice.reducer,
    ratesSearch: RatesSearchSlice_1.ratesSearchSlice.reducer,
    airportSearch: AirportSearchSlice_1.airportSearchSlice.reducer,
});
exports.default = rootReducer;
