"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pending = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_oauth2_pkce_1 = require("react-oauth2-pkce");
const antd_1 = require("antd");
const antd_2 = require("antd");
const antd_3 = require("antd");
const antd_4 = require("antd");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const free_solid_svg_icons_2 = require("@fortawesome/free-solid-svg-icons");
const { Text } = antd_4.Typography;
const Pending = () => {
    const { authService } = (0, react_oauth2_pkce_1.useAuth)();
    const logout = () => __awaiter(void 0, void 0, void 0, function* () {
        authService.logout();
    });
    return ((0, jsx_runtime_1.jsx)(antd_1.Card, Object.assign({ className: "content-inside" }, { children: (0, jsx_runtime_1.jsxs)(antd_2.Space, Object.assign({ direction: "vertical", style: {
                width: '100%',
                textAlign: 'center',
                paddingTop: '2em',
                paddingBottom: '3em',
            } }, { children: [(0, jsx_runtime_1.jsxs)(antd_2.Space, Object.assign({ direction: "vertical", style: { marginBottom: "2em" } }, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSpinner, spin: true, size: '2x' }), (0, jsx_runtime_1.jsx)(Text, { children: "Authentification\u2026" })] })), (0, jsx_runtime_1.jsx)(antd_3.Button, Object.assign({ type: "default", onClick: logout, icon: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_2.faXmark }) }, { children: "Cancel" }))] })) })));
};
exports.Pending = Pending;
exports.default = exports.Pending;
