"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
require("./Header.scss");
const { Title } = antd_1.Typography;
const Header = () => {
    return ((0, jsx_runtime_1.jsxs)("header", Object.assign({ className: "Header" }, { children: [(0, jsx_runtime_1.jsx)(Title, { children: "Rates Assistant" }), (0, jsx_runtime_1.jsx)(Title, Object.assign({ level: 3, type: "secondary" }, { children: "find the best rate for your air cargo" }))] })));
};
exports.Header = Header;
exports.default = exports.Header;
