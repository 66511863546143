"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
const antd_2 = require("antd");
const antd_3 = require("antd");
const antd_4 = require("antd");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const free_solid_svg_icons_2 = require("@fortawesome/free-solid-svg-icons");
const { Text } = antd_4.Typography;
const UserProfile = ({ handleLogout, userProfile }) => {
    return ((0, jsx_runtime_1.jsxs)(antd_2.Space, Object.assign({ direction: 'vertical', style: { textAlign: "center" } }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Avatar, { size: 64, icon: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_2.faUser }) }), (0, jsx_runtime_1.jsx)(Text, { children: userProfile === null || userProfile === void 0 ? void 0 : userProfile.nickname }), (0, jsx_runtime_1.jsx)(antd_3.Button, Object.assign({ type: "default", onClick: handleLogout, icon: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faPowerOff }) }, { children: "Logout" }))] })));
};
exports.UserProfile = UserProfile;
