"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AirportInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const antd_1 = require("antd");
const antd_2 = require("antd");
const antd_3 = require("antd");
const { Option } = antd_1.Select;
const AirportInput = (_a) => {
    var { placeholder, value, handleSearch, loading = false, options = [] } = _a, props = __rest(_a, ["placeholder", "value", "handleSearch", "loading", "options"]);
    return ((0, jsx_runtime_1.jsx)(antd_1.Select, Object.assign({ showSearch: true, optionLabelProp: "label", defaultActiveFirstOption: false, showArrow: false, filterOption: false, notFoundContent: loading ? (0, jsx_runtime_1.jsx)(antd_3.Spin, { size: "small" }) : null, placeholder: placeholder, value: value, onSearch: handleSearch, dropdownMatchSelectWidth: false }, props, { children: (options || []).map((x, idx) => (0, jsx_runtime_1.jsxs)(Option, Object.assign({ value: x.value, label: x.value }, { children: [(0, jsx_runtime_1.jsx)(antd_2.Tag, Object.assign({ style: { fontFamily: "monospace" } }, { children: x.value }), x.value), x.label] }), idx)) })));
};
exports.AirportInput = AirportInput;
exports.default = exports.AirportInput;
