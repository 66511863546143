"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAccessToken = exports.setUserProfile = exports.setUserState = exports.clearUserState = exports.userProfileSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const UserProfileTypes_1 = require("./UserProfileTypes");
const initialUserState = {
    userProfile: {},
    accessToken: "",
};
exports.userProfileSlice = (0, toolkit_1.createSlice)({
    name: UserProfileTypes_1.userProfileNamespace,
    initialState: initialUserState,
    reducers: {
        clearUserState: (state, action) => {
            return initialUserState;
        },
        setUserState: (state, action) => {
            return action.payload;
        },
        setUserProfile: (state, action) => {
            state.userProfile.email = action.payload.email;
            state.userProfile.nickname = action.payload.nickname;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
    },
});
_a = exports.userProfileSlice.actions, exports.clearUserState = _a.clearUserState, exports.setUserState = _a.setUserState, exports.setUserProfile = _a.setUserProfile, exports.setAccessToken = _a.setAccessToken;
