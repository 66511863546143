// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@fontsource/roboto/400.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home{margin:0 auto;max-width:1400px;min-height:100%;padding-bottom:70px}.Home>.ant-card+.ant-card{margin-top:3em}", "",{"version":3,"sources":["webpack://./src/pages/HomePage.scss"],"names":[],"mappings":"AAEA,MACE,aAAA,CACA,gBAAA,CACA,eAAA,CACA,mBAAA,CAGF,0BACE,cAAA","sourcesContent":["@import \"@fontsource/roboto/400.css\";\n\n.Home {\n  margin: 0 auto;\n  max-width: 1400px;\n  min-height: 100%;\n  padding-bottom: 70px;\n}\n\n.Home > .ant-card + .ant-card {\n  margin-top: 3em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
